.icons-line {
  height: 110px;
  min-height: 110px;
  background: $primary-color;
  -webkit-box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.1);

  @include flex-center-y;

  .verified-icon {
    width: 50px;
    height: 52px;

    .cls-1 {
      fill: $secondary-color;
    }
  }

  .realtime {
    width: 50px;
    height: 51px;

    .cls-1 {
      fill: $secondary-color;
    }
  }

  .trusted-destination {
    width: 50px;
    height: 49px;
    margin-bottom: 6px;

    .cls-1 {
      fill: $secondary-color;
    }
  }

  .talk-icon {
    width: 50px;
    margin-top: 5px;
    height: 40px;

    .cls-1 {
      fill: $secondary-color;
    }
  }
  @media (min-width: 768px) and (max-width: 990px) {
    height: 150px;

    .icon_with_text_home_1,
    .icon_with_text_home_2 {
      margin-top: 10px;
    }

    .icon_with_text_home_3,
    .icon_with_text_home_4 {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 768px) {
    height: 310px;

    .icon-with-text {
      margin-top: 10px;
    }
    .icon_with_text_home_4 {
      margin-bottom: 10px;
    }
  }
}
