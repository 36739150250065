.home-newsletter {
  background: $secondary-color;
  padding: $vertical-space 0;

  .page-title {
    @include flex-center-x;
  }

  .fade-out {
    animation-name: auth-model-out-animation;
    animation-duration: 0.7s;
  }

  @keyframes auth-model-out-animation {
    0% {
      opacity: 1;
      filter: alpha(opacity=100);
    }
    100% {
      opacity: 0;
      filter: alpha(opacity=0);
    }
  }

  .fade-in {
    animation-name: auth-model-in-animation;
    animation-duration: 1s;
  }

  @keyframes auth-model-in-animation {
    0% {
      opacity: 0;
      filter: alpha(opacity=0);
    }
    100% {
      opacity: 1;
      filter: alpha(opacity=100);
    }
  }

  .newsletter-success-wrapper {
    @include flex-center;
    min-height: 82px;

    .newsletter-success {
      font-family: $font-2;
      font-size: 17px;
      font-weight: 700;
      color: white;
    }
  }

  h2 {
    text-align: center;
    color: white;
    font-size: 38px;
    font-family: $font-1;
    position: relative;
    float: left;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -13px;
      height: 2px;
      background: white;
    }
  }

  p {
    color: white;
    padding: 15px 0 15px;
    font-family: $font-2;
    font-size: 17px;
    font-weight: 400;
  }

  .white-empty-button {
    font-size: 13px;
    font-weight: 700;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.05);
  }

  input {
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.05);
  }
}
