.filter-row {
  @include flex;

  .filter-side {
    z-index: 15;
  }

  .filter-side-menu-wrapper {
    margin-top: 70px;
  }

  @media (max-width: 991px) {
    display: block !important;

    .filter-side-menu-wrapper {
      margin-top: 0 !important;
    }
  }
}

.background {
  background: #f1f1f1;
  padding-bottom: 70px;
}

.background-white {
  background: white;
}
