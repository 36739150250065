.blog {
  margin-top: 70px;
  padding-left: 15px;
  padding-right: 15px;

  .col-inner {
    background: #fff;
    padding: 0 0 0;
    color: $primary-color;

    div {
      & > li {
        line-height: 1.5;
        font-size: 15px;
        font-family: $font-2;
        color: $primary-color;
      }
    }

    h1 {
      font-family: $font-1;
      padding-bottom: 15px;
      color: $primary-color;
      margin-top: 0;
    }

    h2,
    h3,
    h4 {
      font-family: $font-1;
    }

    p {
      line-height: 1.5;
      padding: 0 0 20px;
      font-size: 15px;
      font-family: $font-2;
      color: $primary-color;
      margin: 0;
    }

    .blog-item {
      margin: 0 0 2.35em;
      border-bottom: 2px solid #f3f2f1;
      padding-bottom: 2em;
      color: $primary-color;

      &:last-child {
        border-bottom: 0;
        margin: 0;
        padding-bottom: 0;
      }

      a {
        color: $primary-color;
        transition: color 0.25s;

        &:hover {
          text-decoration: none;
          color: $secondary-color;
        }
      }

      .blog-title {
        font-size: 28px;
        padding: 0;
        text-align: left;
        font-family: $font-1;
        line-height: 31px;
      }

      .blog-desc {
        margin: 1.4em 0 1.4em;
        line-height: 20px;
        font-size: 15px;
        font-family: $font-2;
      }

      .gold-border-button {
        width: initial;
        padding: 10px 20px;
        font-size: 14px;
      }
    }
  }

  @media (max-width: 991px) {
    .col-inner {
      padding: 0 0 30px !important;
    }
  }

  @media (max-width: 767px) {
    .img-responsive {
      margin: 0 auto 15px;
    }
  }
}
