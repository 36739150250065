.react-select-container {
  .react-select__control {
  }
  .react-select__control--is-focused {
  }
  .react-select__placeholder {
    color: $input-placeholder-color;
  }

  .react-select__option {
  }
  .react-select__option--is-focused {
    background-color: lighten($secondary, 20%);
  }
  .react-select__placeholder {
    color: $input-placeholder-color;
  }

  .react-select__menu {
    z-index: 10;
  }
}

.react-select-container--destinations {
  .react-select__menu {
    @include media-breakpoint-up(sm) {
      //width: 500px;
    }
  }
}
.react-select__obj-type {
  color: $gray-500;
  padding-left: 10px;
  font-size: 11px;
  text-transform: uppercase;
}
