.input-range__track {
  background: $input-range-track-background;
  border-radius: $input-range-track-height;
  cursor: pointer;
  display: block;
  height: $input-range-track-height;
  position: relative;
  transition: $input-range-track-transition;

  .input-range--disabled & {
    background: $input-range-track-disabled-background;
  }
}

.input-range__track--background {
  left: 0;
  margin-top: -0.5 * $input-range-track-height;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: $input-range-track-active-background;
  height: $input-range-track-active-height;
  margin-top: -3px;
}
