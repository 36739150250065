.attribute {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid $link-color;
}
.attribute__icon {
  height: 24px;
  margin-right: 10px;
}
.attribute__value {
  font-weight: bold;
}


.base-attributes {
  @include media-breakpoint-up(md) {
    .row:last-child .attribute {
      border-bottom: none;
    }
  }
}
