.contact {
  padding-left: 15px;
  padding-right: 15px;

  margin-bottom: 60px;

  .col-inner {
    background: #fff;
    border: 1px solid #ddd;
    padding: 35px 40px 40px;
    color: $primary-color;

    -webkit-box-shadow: 0px 0px 31px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 31px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 31px 1px rgba(0, 0, 0, 0.1);


    div {
      & > li {
        line-height: 1.5;
        font-size: 15px;
        font-family: $font-2;
        color: $primary-color;
      }
    }

    h1 {
      font-family: $font-1;
      padding-bottom: 15px;
      color: $primary-color;
      margin-top: 0;
    }

    h2,
    h3,
    h4 {
      font-family: $font-1;
    }

    p {
      line-height: 1.5;
      padding: 0 0 20px;
      font-size: 15px;
      font-family: $font-2;
      color: $primary-color;
      margin: 0;
    }
    ul,
    table {
      font-size: 15px;
    }

    &.about {
      ul {
        padding-left: 18px;
        padding-bottom: 15px;

        li {
          line-height: 1.5;
          padding-bottom: 5px;
          font-size: 15px;
          font-family: $font-2;
          color: $primary-color;
          margin: 0;
        }
      }
    }

    .team {
      margin-top: 20px;

      .team-item {
        strong {
          margin-top: 10px;
          display: block;
          padding: 0;
        }

        p {
          min-height: 100px;
          margin-bottom: 20px;
        }
      }

      strong {
        line-height: 1.5;
        padding: 0 0 30px;
        font-size: 18px;
        font-family: $font-2;
        color: $primary-color;
        margin: 0;
      }

      p {
        margin-top: 5px;
      }
    }

    .team-it {
      margin-top: 20px;

      .team-item {
        strong {
          margin-top: 10px;
          display: block;
          padding: 0;
        }

        p {
          min-height: 150px;
          margin-bottom: 20px;
        }
      }

      strong {
        line-height: 1.5;
        padding: 0 0 30px;
        font-size: 18px;
        font-family: $font-2;
        color: $primary-color;
        margin: 0;
      }

      p {
        margin-top: 5px;
      }
    }

    .thank-you-message {
      background: #388e3c;

      color: #fff;
      margin: 0 0 30px;
      position: relative;
      padding: 30px !important;
      display: inline-block;
      width: 100%;

      animation-name: thank-you-message-in-animation;
      animation-duration: 1s;
      opacity: 1;
      filter: alpha(opacity=1);

      &:before {
        border: 1px solid rgba(255, 255, 255, 0.25);
        content: "";
        position: absolute;
        top: 5px;
        bottom: 5px;
        left: 5px;
        right: 5px;
      }

      p {
        padding: 0 0 10px;
        color: white;
        margin: 0;

        &:last-child {
          padding: 0;
        }
      }
    }

    @keyframes thank-you-message-in-animation {
      0% {
        opacity: 0;
        filter: alpha(opacity=0);
      }
      100% {
        opacity: 1;
        filter: alpha(opacity=100);
      }
    }

    .contact-form {
      font-family: $font-2;
      margin-top: 10px;

      select {
        background: white;
        width: 100%;
        border: 0;
        border-bottom: 0;
        outline: 0;
        padding: 0 20px;
        height: 35px;
        z-index: 1;
        color: #2b3b42;
        font-size: 16px;
        transition: border-color 1s;
        height: 45px;
        font-weight: 300;
        border: 1px solid #ddd;
        font-family: $font-2;
        padding: 0 15px;
        margin-bottom: 19px;
      }

      .text-input {
        padding-top: 0;

        input {
          border: 1px solid #ddd;
          font-family: $font-2;
          padding: 0 15px;
        }

        textarea {
          outline: 0 !important;
          border: 1px solid #ddd;
          width: 100%;
          height: 200px;
          padding: 10px 15px;
          z-index: 1;
          color: $primary-color;
          font-size: 16px;
          transition: border-color 1s;
          height: 45px;
          font-weight: 300;
          height: 200px;
        }
      }

      .hidden-input .text-input {
        margin-bottom: 0;
      }

      label {
        font-family: $font-2;
        display: inline-block;
        cursor: pointer;
        color: #444;
        position: relative;
        font-size: 13px;
        text-transform: uppercase;
        padding: 0 0 5px 1px;
        width: 100%;
        font-weight: normal;

        span {
          color: #ff0045;
        }
      }

      button {
        margin-top: 0 !important;
        height: 50px;
        font-weight: 600;
        font-size: 14px;
      }
    }

    ol {
      margin: 0;
      padding-left: 15px;

      li {
        line-height: 1.5;
        padding: 0 0 20px;
        font-size: 15px;
        font-family: $font-2;
        color: $primary-color;
        margin: 0;
        list-style: decimal outside;
        list-style: decimal outside;
        padding: 0 0 8px 5px;

        span {
          display: block;
          margin-left: 10px;
        }

        a {
          color: $secondary-color;
          font-weight: 600;
        }
      }
    }

    .panel {
      background: white;
      border-radius: 0;

      .panel-heading {
        color: $primary-color;
        border: 0;
        border-color: #ddd;
        cursor: pointer;
        position: relative;
        background-color: $primary-color;
        color: white;
        padding: 0;

        h4 {
          font: 500 17px $font-2;
        }

        a {
          padding: 20px 25px 21px;
          display: block;
        }

        /*&:before {
			    	content: '';
			    	position: absolute;
			    	right: 15px;
			    	width: 20px;
			    	height: 2px;
			    	background: white;
			    	top: 30px;
			    	z-index: 1;
			    }

			    &:after {
			    	content: '';
			    	position: absolute;
			    	right: 24px;
			    	width: 2px;
			    	height: 20px;
			    	background: white;
			    	top: 21px;
			    }*/
      }

      .panel-collapse {
        line-height: 1.5;
        font-size: 15px;
        font-family: $font-2;
        color: $primary-color;
        position: relative;

        .panel-body {
          border: 0;
          padding: 20px 25px 21px;
        }

        span {
          display: block;
          margin-left: 10px;
        }

        a {
          color: $secondary-color;
          font-weight: 600;
        }

        /*&.in {
    				&:after {
				    	content: '';
				    	position: absolute;
				    	right: 24px;
				    	width: 2px;
				    	height: 20px;
				    	background: $primary-color;
				    	top: -41px;
				    }
    			}*/
      }
    }
  }

  @media (max-width: 991px) {
    .col-inner {
      padding: 30px 15px 30px !important;
    }

    .contact-data-inner {
      margin-right: 15px !important;
      margin-left: 15px !important;
      width: calc(100% - 30px) !important;

      ul {
        padding-left: 10px !important;
      }
    }
  }
}

.py1 {
  padding: 10px 0;
}
