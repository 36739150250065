$input-range-font-family: "Open sans";
$input-range-primary-color: #00aeef !default;
$input-range-neutral-color: #aaaaaa !default;
$input-range-neutral-light-color: #e6e7e8 !default;
$input-range-disabled-color: #cccccc !default;

// input-range-slider
$input-range-slider-background: $secondary-color !default;
$input-range-slider-border: 1px solid $input-range-primary-color !default;
$input-range-slider-focus-box-shadow-radius: 5px !default;
$input-range-slider-focus-box-shadow-color: transparentize(
  $input-range-slider-background,
  0.8
) !default;
$input-range-slider-height: 18px;
$input-range-slider-width: 18px;
$input-range-slider-transition: transform 0.3s ease-out,
  box-shadow 0.3s ease-out !default;
$input-range-slider-container-transition: left 0.3s ease-out !default;
$input-range-slider-active-transform: scale(1.1) !default;
$input-range-slider-disabled-background: $input-range-disabled-color !default;
$input-range-slider-disabled-border: 1px solid $input-range-disabled-color !default;

// input-range-label
$input-range-label-color: #001117 !default;
$input-range-label-font-size: 16px !default;
$input-range-label-font-weight: 300 !default;
$input-range-label-position-bottom: -28px !default;
$input-range-label-value-position-top: -1.8rem !default;

// input-range-track
$input-range-track-background: white !default;
$input-range-track-height: 2px;
$input-range-track-active-height: 6px;
$input-range-track-transition: left 0.3s ease-out, width 0.3s ease-out !default;
$input-range-track-active-background: $secondary-color !default;
$input-range-track-disabled-background: $input-range-neutral-light-color !default;
