.gold-button {
  background: $secondary-color;
  color: white;
  width: 100%;
  @include flex-center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  float: left;
  font-family: $font-2;
  border: 0;
  border-radius: 0;
  outline: 0;
}

.dark-button {
  background: $primary-color;
  color: white;
  width: 100%;
  @include flex-center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  float: left;
  font-family: $font-2;
  border: 0;
  border-radius: 0;
  outline: 0;
}

.gold-border-button {
  background: white;
  border: 1px solid $secondary-color;
  color: white;
  width: 100%;
  @include flex-center;
  text-transform: uppercase;
  font-size: 11px;
  float: left;
  font-family: $font-2;
  border-radius: 0;
  outline: 0;
  color: $secondary-color;
  transition: color 0.5s, background 0.5s;

  &:hover {
    color: white;
    background: $secondary-color;
  }
}

.white-empty-button {
  background: transparent;
  border: 1px solid white;
  color: white;
  width: 100%;
  @include flex-center;
  text-transform: uppercase;
  font-size: 11px;
  float: left;
  font-family: $font-2;
  border-radius: 0;
  outline: 0;
  color: white;
  transition: color 0.5s, background 0.5s;

  &:hover {
    color: $secondary-color;
    background: white;
  }
}
