.equipment {
  margin-bottom: $vertical-space/2;
}
.equipment__category {
  font-weight: bold;
}
.equipment__item {
}
.equipment__item-check {
  height: 12px;
  margin-right: 5px;
}
