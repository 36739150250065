.home-filter {
  margin-bottom: $vertical-space;
  label {
    font-weight: bold;
    text-transform: uppercase;
  }
  .btn--home-filter {
    margin-top: 28px;
    width: 100%;
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: 246px;
  }
}
