.offer-label {
  position: absolute;
  top: 13px;
  left: -5px;
  z-index: 1;
  text-transform: uppercase;
  padding: 5px 10px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  background-color: $primary-color;

  @media (max-width: 992px) {
    top: 7px;
  }
}

.offer {
  .offer-label {
    top: -170px;
    @media (max-width: 992px) {
      top: -180px;
    }
  }
}
