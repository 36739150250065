.quick-contact {
  @include shadow;
  background: white;
  text-align: center;
  font-size: 14px;
  padding: 10px 10px;
}
.quick-contact__header, .quick-contact__contacts {
  margin: 10px 0;
}
.quick-contact__title {
  font-size: 16px;
  font-weight: bold;
}
.quick-contact__subtitle {
}
.quick-contact__img {
  border-radius: 50% !important;
  max-width: 130px;
  margin: 0 auto;
}
