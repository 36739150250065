.offer-navigation {
  background: $primary-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: $vertical-space/2;
}
.offer-navigation__div {
}
