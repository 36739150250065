.home-icon-wrapper {
  @include flex-center-x;

  .home-icon {
    text-align: center;
    @include flex-center-y;
    flex-direction: column;
    width: 100%;

    .home-icon--icon {
      height: 85px;
      width: 85px;
      border: 2px solid #f1f1f1;
      border-radius: 50%;
      @include flex-center;
      transition: border 0.5s, background 0.5s;

      -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.16);

      &:hover {
        border: 1px solid $secondary-color;
        background: $secondary-color;

        .hand-lens {
          height: 35px;
          width: 35px;

          .cls-1 {
            stroke: white;
          }
          .cls-2 {
            stroke: white;
          }
        }

        .calendar {
          width: 35px;
          height: 35px;

          .cls-1 {
            transition: stroke 0.5s;
            stroke: transparent;
            stroke-width: 0;
            fill: white;
          }
        }

        .reservation-icon {
          width: 33px;
          height: 36px;

          .cls-1 {
            stroke: white;
            fill: white;
          }
        }

        .free-icon {
          height: 46px;
          width: 35px;
          .cls-1 {
            stroke: white;
          }
        }
      }

      .hand-lens {
        width: 35px;

        .cls-1 {
          transition: stroke 0.5s;
          stroke: $secondary-color;
          stroke-width: 3px;
        }

        .cls-2 {
          transition: stroke 0.5s;
          stroke: $secondary-color;
          stroke-width: 2px;
        }
      }

      .free-icon {
        width: 35px;

        .cls-1 {
          transition: stroke 0.5s;
          stroke: $secondary-color;
          stroke-width: 2px;
        }

        .cls-2 {
          transition: stroke 0.5s;
          stroke: $secondary-color;
          stroke-width: 2px;
        }
      }

      .reservation-icon {
        width: 33px;

        .cls-1 {
          transition: stroke 0.5s, fill 0.5s;
          stroke: $secondary-color;
          stroke-width: 1px;
          fill: $secondary-color;
        }
      }

      .calendar {
        width: 35px;

        .cls-1 {
          transition: fill 0.5s;
          stroke: transparent;
          stroke-width: 0;
          fill: $secondary-color;
        }
      }
    }

    .home-icon--title {
      font-size: 17px;
      padding: 18px 0 9px;
      font-family: $font-2;
      font-weight: 700;
      width: 100%;

      a {
        color: $primary-color;
        transition: color 0.5s;

        &:hover {
          text-decoration: none;
          color: $secondary-color;
        }
      }
    }

    .home-icon--text {
      color: $primary-color;
      font-size: 14px;
      font-family: $font-2;
      width: 100%;

      a {
        color: $secondary-color;
        transition: color 0.5s;

        &:hover {
          text-decoration: none;
          color: $primary-color;
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
