.list-price {
  color: $secondary;
  text-decoration: line-through;
}
.price {
}

.price-block {
  display: block;
  overflow: hidden;
  font-size: 13px;
  line-height: 1;
  color: $primary-color;
  font-family: $font-2;

  .discount {
    display: inline-block;
    color: $secondary-color;
    float: right;
    margin: 0 3px;
    padding: 12px 4px;
    background-color: #fff;
    border: 1px solid $secondary-color;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    overflow: hidden;
    text-align: center;
    font-size: 15px;
    font-weight: 600;

    &:after {
      content: "%";
      font-weight: 300;
    }
  }

  .price {
    display: block;
    padding-top: 5px;
    font-size: 20px;
    font-weight: 600;
  }
}

.price-block--box {
  padding: 8px;
  border: 1px solid $secondary-color;
}
.price-block--hover {
  transition: background 1s;
  &:hover {
    background: $secondary-color;
    color: white;
    text-decoration: none;

    .list-price,
    .price {
      color: white;
    }
  }
}
