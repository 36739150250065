.page-inner {
  padding: 30px;
  border: 1px solid $border;
  box-shadow: 0px 0px 31px 1px rgba(0, 0, 0, 0.1);
}


.page-home {
}
.page-home__block {
  margin-top: $vertical-space;
  margin-bottom: $vertical-space;
}
