.not-found {
  background: #f1f1f1;

  @include flex-center;
  min-height: calc(100vh - 96px - 200px);

  .compass-wrapper {
    @include flex-center;

    .compass-icon {
      width: 300px;

      .cls-1 {
        fill: $secondary-color;
      }
    }
  }

  .text-wrapper {
    @include flex-center-x;
    flex-direction: column;

    h1 {
      font-weight: normal;
      font-size: 31px;
      padding: 0 0 25px;
      color: $primary-color;
      font-family: $font-1;
    }

    p {
      line-height: 1.5;
      padding: 0 0 20px;
      font-size: 15px;
      font-family: $font-2;
      color: $primary-color;

      a {
        color: $secondary-color;
        text-decoration: none;
        font-weight: 600;

        &:hover {
          color: $secondary-color;
          text-decoration: none;
        }
      }
    }
  }

  .row-not-found {
    @include flex;
  }

  @media (max-width: 767px) {
    .compass-wrapper {
      margin-top: 20px;

      .compass-icon {
        width: 100px;
      }
    }

    .row-not-found {
      display: block;
    }
  }
}
