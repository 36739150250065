.home-blog-wrapper {
  background: $grey-color;

  @include flex-center-x;


  .page-title {
    @include flex-center-x;
  }

  -webkit-box-shadow: inset 0px 0px 96px -3px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0px 0px 96px -3px rgba(0, 0, 0, 0.03);
  box-shadow: inset 0px 0px 96px -3px rgba(0, 0, 0, 0.05);

  .gold-border-button {
    background: transparent;
    height: 50px;
    border: 2px solid $secondary-color;
    font-size: 15px;
    font-weight: 600;
    margin-top: 25px;
    outline: 0 !important;

    &:hover {
      background: $secondary-color;
      outline: 0 !important;
    }

    &:focus,
    &:active {
      outline: 0 !important;
      color: white;
      background-color: $secondary-color;
      border-color: $secondary-color;
    }
  }

  .home-blog {
    color: $primary-color;
    background: white;
    margin: 0px auto 0 auto;
    display: flex;
    flex-direction: column;

    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.05);

    .home-blog-image {
      img {
        width: 100%;
      }
    }

    .home-blog-bottom {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      width: 100%;
      border: 1px solid #ddd;
      padding: 20px 30px;
      float: left;
      background: white;

      .home-blog-title {
        font: 600 18px $font-2;
        padding: 0 0 15px;
        min-height: auto;
        min-height: 59px;
        max-height: 59px;
        height: 59px;
        @include flex-center-y;
      }

      .home-blog-text {
        min-height: 155px;
        border-top: 1px solid $secondary-color;
        padding: 13px 0 15px;
        font-size: 14px;
        font-family: $font-2;
        text-align: left;
        flex: 1 0 auto;
      }

      .home-blog-button {
        float: left;
        width: 100%;

        a {
          float: left;
          width: 100%;
        }

        button {
          font-size: 13px;
          height: 42px;
          line-height: 38px;
          font-weight: 700;
        }
      }
    }
  }
}
