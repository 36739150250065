.no-results {
  margin-top: $vertical-space;
  display: flex;
  flex-direction: column;
  align-items: center;

  .compass-icon {
    width: 40%;

    .cls-1 {
      fill: $secondary-color;
    }
  }

  .no-results__text {
    margin-top: 50px;
    font-family: $font-2;
    font-weight: normal;
    font-size: 31px;
    color: $primary-color;
  }
}
