.page-home {


}


.page-home__title {
  display: flex;
  @extend .mb-4;
  h2 {
    color: $primary-color;
    font-size: 38px;
    font-family: $font-1;
    position: relative;
    float: left;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -13px;
      height: 2px;
      background: $secondary-color;
    }
  }
}
