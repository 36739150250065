.google-map {
  float: left;
  width: 100%;
  height: 620px;

  .pin {
    position: absolute;
    bottom: 0px;
    left: -33px;
  }

  .cluster {
    position: absolute;
    height: 50px;
    width: 50px;
    background: white;
    border-radius: 50%;
    @include flex-center;
    cursor: pointer;

    .cluster-inner {
      height: 43px;
      width: 43px;
      background: $secondary-color;
      border-radius: 50%;
      @include flex-center;

      color: white;
      font-size: 17px;
      font-weight: 700;
      font-family: $font-2;
    }
  }

  .google-map-open {
    position: absolute;
    width: 322px;
    height: auto;
    bottom: 75px;
    z-index: 9999;
    background: white;
    left: -169px;
    font-family: $font-2;
    padding: 15px;
    border-bottom: 6px solid $secondary-color;

    animation-name: pin-in-animation;
    animation-duration: 0.5s;
    opacity: 1;
    filter: alpha(opacity=10);
    cursor: initial;

    &:after {
      content: "";
      position: absolute;
      bottom: -19px;
      left: 50%;
      height: 0px;
      width: 0px;
      border-top: 10px solid $secondary-color;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      margin-left: -10px;
      border-bottom-style: solid;
      border-bottom-color: transparent;
    }

    @keyframes pin-in-animation {
      0% {
        opacity: 0;
        filter: alpha(opacity=0);
      }
      100% {
        opacity: 1;
        filter: alpha(opacity=100);
      }
    }

    .close {
      position: absolute;
      right: 15px;
      top: 15px !important;
      top: 5px;
      width: 12px;
      height: 12px;

      opacity: 1;
      filter: alpha(opacity=100);

      .cls-1 {
        stroke: $primary-color;
        stroke-miterlimit: 10;
        stroke-width: 4px;
      }
    }

    .gold-border-button {
      margin-top: 15px;
      padding: 5px 0;
      color: #bb9c6f;

      &:hover {
        color: white;
      }
    }

    .marine-name {
      float: left;
      width: 100%;
      @include flex-center;

      h3 {
        margin-top: 5px;
        margin-bottom: 17px;
        font-weight: 700;
      }
    }

    .marine-img {
      img {
        max-width: 100%;
      }
    }
  }
}
