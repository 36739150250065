.CookieLaw {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 66px;
  background: $primary-color;
  z-index: 10000;

  .CookieLaw__inner {
    clear: both;
    float: left;
    width: 100%;
    @include flex;
  }

  .CookieLaw__left {
    padding: 13px 30px 13px 0;
    @include flex-center-y;

    .CookieLaw__left__info {
      display: inline-block;
      font-family: $font-2;
      font-size: 19px;
      border-radius: 50%;
      color: white;
      border: 2px solid white;
      padding: 0 11px;
      margin-right: 15px;
    }

    .CookieLaw__left__infoText {
      margin-left: 3px;
      font-family: "Open sans";
      font-size: 16px;
      color: white;
    }
  }

  .CookieLaw__right {
    margin-top: 12px;
  }

  @media (max-width: 767px) {
    height: auto;

    .CookieLaw__left {
      padding-right: 13px;
    }
    .CookieLaw__right {
      padding-bottom: 13px;
    }

    .CookieLaw__inner {
      display: block;
    }
  }
}
