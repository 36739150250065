.promo-modal {
  position: relative;
  @media (min-height: 900px) {
    margin-top: 200px;
  }

  .modal-body {
    padding: 0 30px 30px;
  }

  .promo-img {
    width: 100%;
    @include flex-center;
  }

  .image-50 {
    position: absolute;
    top: -180px;
    left: -150px;

    -ms-transform: rotate(-10deg); /* IE 9 */
    -webkit-transform: rotate(-10deg); /* Chrome, Safari, Opera */
    transform: rotate(-10deg);
  }

  .close {
    z-index: 10;
    height: 20px;
    width: 20px;
  }

  .modal-content {
    border-radius: 0;
  }

  .modal-header {
    border: 0;
  }

  .dark-button {
    margin-top: 10px !important;
    height: 56px !important;
  }

  form {
    font-family: $font-2;

    select {
      background: white;
      width: 100%;
      border: 0;
      border-bottom: 0;
      outline: 0;
      padding: 0 20px;
      height: 35px;
      z-index: 1;
      color: #2b3b42;
      font-size: 16px;
      transition: border-color 1s;
      height: 45px;
      font-weight: 300;
      border: 1px solid #ddd;
      font-family: $font-2;
      padding: 0 15px;
      margin-bottom: 19px;
    }

    .text-input {
      padding-top: 0;

      input {
        border: 1px solid #ddd;
        font-family: $font-2;
        padding: 0 15px;
      }

      textarea {
        outline: 0 !important;
        border: 1px solid #ddd;
        width: 100%;
        height: 200px;
        padding: 10px 15px;
        z-index: 1;
        color: $primary-color;
        font-size: 16px;
        transition: border-color 1s;
        height: 45px;
        font-weight: 300;
        height: 200px;
      }

      button {
        background: $primary-color;
      }
    }

    .hidden-input .text-input {
      margin-bottom: 0;
    }

    label {
      font-family: $font-2;
      display: inline-block;
      cursor: pointer;
      color: #444;
      position: relative;
      font-size: 13px;
      text-transform: uppercase;
      padding: 0 0 5px 1px;
      width: 100%;
      font-weight: normal;

      span {
        color: #ff0045;
      }
    }

    button {
      margin-top: 0 !important;
    }
  }

  .promo-bold {
    font-family: $font-2;
    font-size: 18px;
    margin: 30px 0 35px;
  }

  .promo-footer {
    padding: 50px 30px 0;
    font-weight: 700;
    font-family: $font-2;
    font-size: 12px;
    text-align: center;
  }

  .promo-title {
    margin-bottom: 20px;
  }

  form a {
    margin: 20px 0;
    float: left;
    width: 100%;
    @include flex-center;

    button {
      width: auto;
      padding: 10px 20px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .close {
    span {
      font-size: 33px;
      position: absolute;
      right: 17px;
      top: 11px;
      opacity: 1;
    }
  }
}
