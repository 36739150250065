.offer-teaser-map {
  height: 300px;
  margin-bottom: $vertical-space;
}

.offer-teaser {
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 10px 24px -8px rgba(0, 0, 0, 0.09);
  margin-bottom: 15px;

}

.offer-teaser__left {
  display: flex;
  flex-direction: column;
}
.offer-teaser__location {
  padding-bottom: 8px;
  border-bottom: 1px solid $secondary;
  margin-bottom: 10px;
}
.offer-teaser__properties {
  flex: 1 1 auto;
  .row {
    margin-bottom: 10px;
    margin-left: -5px;
    margin-right: -5px;
  }
  .col {
    padding-left: 5px;
    padding-right: 5px;
  }

  .property-label {
  }
  .property-value {
  }
}
.offer-teaser__map-button {
}

.offer-teaser__center {
  @include media-breakpoint-down(md) {
    margin: $vertical-space 0;
  }
}
.offer-teaser__right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// CAROUSEL
.offer-carousel {
  position: relative;
  height: 100%;

  .heart-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    z-index: 100;
    cursor: pointer;
    border-radius: 50%;
    transition: background 0.5s;
    @include flex-center;

    .heart {
      margin-top: 3px;
      width: 25px;

      transition: width 0.2s;

      .cls-1 {
        stroke: white;
      }

      &:hover {
        width: 30px;
      }
    }

    &.heart-wrapper--active {
      background: $secondary-color;

      .heart {
        &:hover {
          width: 25px;
        }
      }
    }
  }

  .item {
  }
}

.offer-carousel__carousel {
  height: 100%;

  .carousel-inner {
    height: 100%;
  }
  .carousel-offer-item {
    height: 100%;
    background-position: center center;
    background-size: cover;
  }
  .carousel-item {
    height: 100%;
    min-height: 223px;
  }
}
