.similar-offers {
  background: $gray-200;
  border: 1px solid $gray-500;
}

.similar-offers__offer-period {
  font-size: 12px;
  a {
    color: $body-color;
    text-decoration: none;
    @extend .stretched-link;
  }
}
.similar-offers__offer-price {
  font-weight: bold;
}
.similar-offers__offer-not-available {
  font-weight: bold;
}
.similar-offers__offer {
  position: relative;
  text-align: center;
  padding: 5px $card-spacer-x;
  border-left: 1px solid $gray-500;
}
.similar-offers__offer--disabled {
  .similar-offers__offer-price {
    color: $danger;
    font-weight: normal;
  }
}
.similar-offers__offer--current {
    background-color: lighten($secondary, 15%);
}
.similar-offers__offer--available {
  cursor: pointer;
  &:hover {
    background: $secondary;
  }
}
