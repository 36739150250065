.featured-yachts {
}

.featured-yacht {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin-bottom: $vertical-space;
  .location {
    margin-bottom: 5px;
  }
  > div {
  }
}
.featured-yacht__name {
  a {
    @extend .stretched-link;
    &:hover {
      text-decoration: none;
    }
  }
}
.featured-yacht__price {
}
.featured-yacht__image-container {
  margin-bottom: 5px;
}
.featured-yacht__image {
  object-fit: cover;
}
