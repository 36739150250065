.icon-with-text {
  @include flex-center-y;

  .icon-with-text--icon {
    min-height: 56px;
    @include flex-center-y;
  }

  .icon-with-text--text {
    color: white;
    margin-left: 20px;
    font-weight: 300;
    font-size: 18px;
    font-family: $font-2;
  }
}
