.inquiry__header-description {
  font-size: 14px;
  margin-top: 12px;

  span {
    font-weight: 700;
  }
}



.thank-you-message {
  margin-top: 0px;
  font-size: 14px;
  margin-bottom: 20px;
}

.thank-you-message-footer {
  float: left;
  width: 100%;
  margin-top: 20px;
}

.enquiry-link {
  margin-bottom: 20px;
  float: left;

  img {
    width: 100%;
  }
}

