@mixin flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex-center {
  @include flex;
  align-items: center;
  -webkit-align-items: center; /* Safari 7.0+ */
  justify-content: center;
  -webkit-justify-content: center; /* Safari 7.0+ */
}

@mixin flex-center-x {
  @include flex;
  justify-content: center;
  -webkit-justify-content: center; /* Safari 7.0+ */
}

@mixin flex-center-y {
  @include flex;
  align-items: center;
  -webkit-align-items: center; /* Safari 7.0+ */
}

@mixin drop-shadow() {
  -webkit-box-shadow: 10px 10px 23px -12px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 10px 10px 23px -12px rgba(0, 0, 0, 0.27);
  box-shadow: 10px 10px 23px -12px rgba(0, 0, 0, 0.27);
}
