.offer-filter-container {
}

.offer-filter {
  label {
  }
}

.search-box {
  @include sidebar-box;
  label {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    span {
      font-weight: 400;
    }
  }
}

.search-box--primary {
  background: $primary-color;

  label {
    color: white;
  }
}
.search-box--extended {
  background: #fff;
}

.filter-side {
  .quick-contact {
    @include sidebar-box;
  }
}

.btn--search {
  width: 100%;
}
