.destinations {
  .destinations-footer {
    color: white;
    background: $primary-color;
    padding: 12px 8px 12px 24px;
    font-size: 13px;
    font-weight: 400;
    font-family: $font-2;
  }

  .dropdown-with-seatch {
    position: absolute;
    width: 300px;
    height: 600px;
    z-index: 10;
    margin-top: 20px;

    .cd-accordion-menu {
      width: 100%;
      max-width: 600px;
      background: white;
      list-style-type: none;
      margin-bottom: 0;
      padding: 0;

      label {
        margin-bottom: 0;
      }

      & > li {
        padding: 12px 8px 0 12px;
        font-family: $font-2;
        color: black;
        font-size: 13px;
        font-weight: 400;
        background: 0;

        &:first-child {
          padding-top: 24px;
        }

        &:last-child {
          padding-bottom: 24px;
        }

        & > label {
          //padding-bottom: 12px;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;
      }
    }
    .cd-accordion-menu ul {
      /* by default hide all sub menus */
      display: none;
    }
    .cd-accordion-menu li {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .cd-accordion-menu input[type="checkbox"] {
      /* hide native checkbox */
      display: none;
    }
    .cd-accordion-menu label,
    .cd-accordion-menu a {
      font-family: $font-2;
      color: black;
      font-size: 13px;
      font-weight: 400;
    }
    .no-touch .cd-accordion-menu label:hover,
    .no-touch .cd-accordion-menu a:hover {
      background: #52565d;
    }
    .cd-accordion-menu input[type="checkbox"]:checked + label + ul,
    .cd-accordion-menu
      input[type="checkbox"]:checked
      + label:nth-of-type(n)
      + ul {
      /* use label:nth-of-type(n) to fix a bug on safari (<= 8.0.8) with multiple adjacent-sibling selectors*/
      /* show children when item is checked */
      display: block;
    }

    label {
      width: 100%;
    }

    .destinations-destinations {
      float: left;
      width: 100%;
      position: relative;
      background: white;

      box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.4);
      border-bottom: 1px solid $secondary-color;

      @include flex-center-y;
      padding: 16px 8px 16px 24px;

      .anchor-icon {
        float: left;
        width: 17px;
        margin-right: 10px;
      }

      h1 {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        color: $primary-color;
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 0;
        float: left;
      }
    }

    & > div {
      position: initial !important;
    }
  }

  .destination {
    cursor: pointer;
    min-height: 380px;
    height: 380px;
    background-position: center;
    background-size: cover;
    float: left;
    width: 100%;
    position: relative;
    z-index: 1;
    overflow: hidden;

    @include flex-center;
    flex-direction: column;

    h2 {
      text-align: center;
      color: white;
      font-size: 38px;
      font-family: $font-1;
      position: relative;
      float: left;
    }

    .description {
      color: white;
      font-family: "Lato", sans-serif;
      font-size: 17px;
      font-weight: 400;
      text-align: center;
      width: 25%;
      max-height: 0;
      opacity: 0;
      transition: all 2s;
    }

    button {
      height: 50px;
      width: 250px;
      margin-top: 20px;
      max-height: 0;
      opacity: 0;
      transition: all 2s;
    }

    &:before {
      border: 1px solid rgba(255, 255, 255, 0.5);
      content: "";
      position: absolute;
      top: 20px;
      bottom: 20px;
      left: 20px;
      right: 20px;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: black;
      opacity: 0.4;
      filter: alpha(opacity=40);
      z-index: -1;
    }

    &:hover {
      h2 {
        margin-bottom: 20px;
      }

      .description {
        display: block;
        opacity: 1;
        max-height: 1000px;
      }

      button {
        display: block;
        opacity: 1;
        max-height: 1000px;
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    .destination {
      min-height: 450px;
    }
  }

  @media (max-width: 1200px) {
    h2 {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 500px) {
    .destination {
      min-height: 200px;
    }
  }
}

.cd-accordion-menu__label {
  padding: 6px 0 6px 24px;
}
.cd-accordion-menu__label--toggle {
  background: url(/static/media/ic-right.svg) center left no-repeat;
  cursor: pointer;
}
.cd-accordion-menu input[type="checkbox"]:checked + label {
  background-image: url(/static/media/ic-down.svg);
}

.cd-accordion-menu ul .cd-accordion-menu__label {
  padding-left: 48px;
  background-position: 24px center;
}
.cd-accordion-menu ul ul .cd-accordion-menu__label {
  padding-left: 72px;
  background-position: 48px center;
}

.cd-accordion-menu__label > a {
  cursor: pointer;
}
