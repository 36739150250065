@import "input-range-variables";
@import "input-range-slider";
@import "input-range-label";
@import "input-range-label-container";
@import "input-range-track";

.input-range {
  height: $input-range-slider-height;
  position: relative;
  margin-left: 12px;
  margin-right: 6px;
}
