.home-header {
}
.home-header__paralax {
}
.home-header__container {
  min-height: 60vh;
  max-height: calc(100vh - #{$navHeight});
  display: flex;
  align-items: center;
}

$header-font-size: 13px;
.home-header__content {
  width: 100%;

  label {
    color: white;
  }
  h1,
  h2 {
    color: white;
    text-align: center;
  }
  h1 {
    width: 100%;
    font-family: $font-1;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    text-shadow: 3px 3px 10px #666;
    margin-top: 20px;
  }

  h2 {
    font-family: $font-1;
    font-size: 15px;
    text-transform: uppercase;
    text-shadow: 3px 3px 10px #222;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 1.2;
  }

  @include media-breakpoint-up(sm) {
    h1 {
      font-size: $header-font-size*3*0.87;
    }
    h2 {
      font-size: $header-font-size*1.2*0.87;
      margin-bottom: 80px;
    }
  }
  @include media-breakpoint-up(lg) {
    h1 {
      font-size: $header-font-size*3;
    }
    h2 {
      font-size: $header-font-size*1.2;
    }
  }
}
