.wishlist {
  .wishlist-main {
    min-height: 700px;

    .menu-side {

      ul {
        margin-bottom: 50px;
        list-style-type: none;
        background: white;
        padding-left: 0;
        -webkit-box-shadow: 0px 10px 62px -18px rgba(0, 0, 0, 0.34);
        -moz-box-shadow: 0px 10px 62px -18px rgba(0, 0, 0, 0.34);
        box-shadow: 0px 10px 62px -18px rgba(0, 0, 0, 0.34);

        li {
          padding: 10px 15px 10px 24px;
          cursor: pointer;
          color: $primary-color;
          font-family: $font-2;
          font-size: 14px;
          font-weight: 400;

          &:nth-child(2) {
            padding-top: 20px;
          }

          &:first-child {
            padding: 16px 15px 16px 24px;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 700;
            color: white;
            /*padding-top: 16px;*/
            margin-top: 0;
            margin-bottom: 0;
            background: $primary-color;
            -webkit-box-shadow: 0px 10px 33px -8px rgba(0, 0, 0, 0.11);
            -moz-box-shadow: 0px 10px 33px -8px rgba(0, 0, 0, 0.11);
            box-shadow: 0px 10px 33px -8px rgba(0, 0, 0, 0.11);

            .menu-class {
              font-size: 16px;
              margin-left: 5px;
            }

            .anchor-wrapper {
              @include flex-center-y;
              /*padding-bottom: 10px;
              border-bottom: 1px solid #4f6169;*/
            }

            .yacht-data {
              padding-top: 10px;

              .enquiry-modal-title-data-item {
                margin-bottom: 3px;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }

          &:nth-child(4) {
            padding-bottom: 20px;
          }

          &:last-child {
            padding: 0;
          }

          .caret-down {
            -ms-transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
            cursor: pointer;
            width: 15px;
            float: right;
            margin-top: 4px;

            .cls-1 {
              stroke-width: 1px;
            }
          }

          .anchor-icon {
            float: left;
            width: 17px;
            height: 17px;
            margin-right: 10px;

            .cls-1 {
              fill: white;
            }
          }

          .gold-button {
            float: left;
            height: 65px;
          }
        }
      }
    }

    .wishlist-wrapper {
    }
  }

  .wishlist-offer {
    margin-bottom: 20px;

    .wishlist-offer-image {
      margin-bottom: 20px;
      position: relative;

      .wishlist-image {
        width: 100%;
        height: 150px;
        background-position: center;
        background-size: cover;
      }

      .wishlist-close-wrapper {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        z-index: 1;

        .close {
          width: 20px;
          height: 20px;
          opacity: 1;
          filter: alpha(opacity=100);

          .cls-1 {
            stroke: white;
            stroke-width: 4px;
          }
        }
      }
    }

    .wishlist-offer-row {
      font-family: $font-2;
      color: $primary-color;
      font-size: 16px;

      .wishlist-offer-row--title {
        font-weight: 600;
        text-transform: uppercase;
        padding-bottom: 5px;
        border-bottom: 1px solid #f3f4f5;
      }

      .wishlist-offer-row--value {
        font-weight: 400;
        padding-top: 8px;
        padding-bottom: 18px;

        &.wishlist-offer-row--marina {
          min-height: 70px;
        }
      }

      .wishlist-offer-price-wrapper {
        .wishlist-offer-price {
          float: left;
          color: rgba(16, 37, 66, 0.35);
          text-decoration: line-through;
          height: 26px;
          @include flex-center-y;
        }

        .wishlist-offer-discount {
          float: left;
          background: $secondary-color;
          padding: 2px 5px;
          color: white;
          margin-left: 5px;
        }
      }

      .wishlist-offer-final-price {
        float: left;
        width: 100%;
        font-size: 26px;
        font-weight: 700;
        color: $secondary-color;
      }
    }
  }
}
