.offer-detail {
}

.offer-detail__header {
}

.offer-detail__header-line {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
  }
  > div {
    margin-bottom: 5px;
    margin-bottom: $vertical-space / 2;
  }
}

.offer-detail__buttons {
  svg {
    height: 18px;
    margin-right: 10px;
  }
  a, button {
    margin-left: 5px;
    margin-bottom: 5px;
    &:first-child {
      margin-left: 0px;
    }
  }
  @include media-breakpoint-up(lg) {
    flex: 1 0 auto;
    text-align: right;
  }
}


.btn--inquiry {
  font-weight: bold;;
}
