.hand-lens {
  .cls-1,
  .cls-2 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
  }
  .cls-1 {
    stroke-width: 4px;
  }
  .cls-2 {
    stroke-width: 2.01px;
  }
}

.caret-down {
  .cls-1 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 2px;
  }
}

.sailmaster-si-le {
  cursor: pointer;
  width: 191px;
  height: 58px;
  .cls-1 {
    fill: #c5aa82;
  }
  .cls-2 {
    fill: #2b3b42;
  }
  .cls-3 {
    fill: #e6d4bd;
  }
  .cls-4 {
    fill: #fff;
  }
  .cls-5 {
    fill: #2b3b42;
  }
  .cls-6 {
    fill: #c5aa82;
  }
}

.sailmaster-it-le {
  cursor: pointer;
  width: 191px;
  height: 58px;
  .cls-1 {
    fill: #2b3b42;
  }
  .cls-2 {
    fill: #c5aa82;
  }
  .cls-3 {
    fill: #e6d4bd;
  }
  .cls-4 {
    fill: #fff;
  }
  .cls-5 {
    fill: #2b3b42;
  }
  .cls-6 {
    fill: #c5aa82;
  }
}

.sailmaster-it-po {
  cursor: pointer;
  width: 191px;
  height: 105px;
  .cls-1 {
    fill: #2b3b42;
  }
  .cls-2 {
    fill: #c5aa82;
  }
  .cls-3 {
    fill: #e6d4bd;
  }
  .cls-4 {
    fill: #fff;
  }
}

.sailmaster-si-po {
  cursor: pointer;
  width: 191px;
  height: 105px;
  .cls-1 {
    fill: #c5aa82;
  }
  .cls-2 {
    fill: #2b3b42;
  }
  .cls-3 {
    fill: #e6d4bd;
  }
  .cls-4 {
    fill: #fff;
  }
}

.navtika-icon {
  height: 30px;

  .cls-1 {
    fill: url(#New_Gradient_Swatch_2);
  }
  .cls-2 {
    fill: url(#New_Gradient_Swatch_1);
  }
  .cls-3 {
    fill: url(#linear-gradient);
  }
  .cls-4 {
    fill: url(#linear-gradient-2);
  }
  .cls-5 {
    fill: url(#linear-gradient-3);
  }
  .cls-6 {
    fill: url(#linear-gradient-4);
  }
  .cls-7 {
    fill: url(#linear-gradient-5);
  }
  .cls-8 {
    fill: #0091ff;
  }
  .cls-9 {
    fill: #ccc;
  }
}

.vzajemna-icon {
  height: 45px;

  .cls-1 {
    fill: none;
  }
  .cls-2 {
    clip-path: url(#clip-path-1);
  }
  .cls-3 {
    fill: url(#linear-gradient-1);
  }
  .cls-4 {
    fill: white;
  }
}
.YachtPool-icon {
  height: 45px;
}

.nausys-logo {
  .cls-1,
  .cls-3 {
    fill: #fff;
  }
  .cls-2 {
    fill: #2b68b2;
  }
  .cls-3 {
    fill-rule: evenodd;
  }
}

.heart {
  .cls-1 {
    fill: none;
    stroke: #000;
    stroke-miterlimit: 10;
    stroke-width: 4px;
  }
}
