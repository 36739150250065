.offer-header {
  background: $secondary-color;
  padding-bottom: 80px;
  background-size: cover !important;
  position: relative;

  .offer-header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    opacity: 0.5;
    filter: alpha(opacity=50); /* For IE8 and earlier */
  }

  .page-title {
    @include flex-center-x;
  }

  p {
    color: white;
    padding: 15px 0 15px;
    font-family: $font-2;
    font-size: 17px;
    font-weight: 400;
    text-align: center;
  }

  .page-description {
    margin-bottom: 190px;
  }

  .margin-bottom190 {
    margin-bottom: 190px;
  }

  h1 {
    margin-top: 130px;
    margin-bottom: 40px;
    text-align: center;
    color: white;
    font-size: 38px;
    font-family: $font-1;
    position: relative;
    float: left;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -13px;
      height: 2px;
      background: white;
    }
  }
}
