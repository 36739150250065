.navbar--navbar2 {
  padding-top: 0;
  padding-bottom: 0;
  background: #fff;
  .navbar-brand {
    padding: 0;
    svg {
      padding: 5px 0;
      height: $navHeight;
      width: auto;
    }
  }
  .navbar-collapse {
    align-self: flex-end;
  }
  font-weight: 700;
  .navbar-nav {
    margin-bottom: 3px;
  }
}

.navbar-icon {
  width: auto;
  height: 22px;
  margin-top: 2px;
}
.navbar-icon--heart {
  .cls-1 {
    fill: none;
    stroke: #323D43;
    stroke-miterlimit: 10;
    stroke-width: 4px;
  }
}

.btn--request-offer {
  font-weight: bold;
}


.flag {
  width: 25px;
  border-radius: 2px;
  margin-top: -3px;
  margin-right: 5px;
}

.badge.btn-wishlist__badge {
  top: 7px;
}
