.DateRangePicker_picker {
  z-index: 10;
}
.calendar-date-info {
  padding: 13px;
}
.DateInput {
  width: 100px;
  width: calc((100% - 20px) / 2);
}
.DateInput_input::placeholder {
    color: $input-placeholder-color;
}
.DateRangePickerInput_arrow_svg {
  height: 18px;
  width: auto;
}
.DateInput_input {
  font-size: $font-size-base;
  color: #000;
  font-weight: inherit;
}
.DateInput_input__focused {
  border-color: $secondary;
}
.CalendarDay__default {
  border: 0 !important;
  padding: 0 !important;
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover,
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  color: #cacccd;
}

.date-range-select--focus-startDate {
  .CalendarDay__default:not(.CalendarDay__blocked_calendar):not(.CalendarDay__blocked_out_of_range):hover {
    background-image: url(/static/images/calendar/start-hover.svg) !important;
    color: #fff;
  }
}
.date-range-select--focus-endDate {
  .CalendarDay__default:not(.CalendarDay__blocked_calendar):not(.CalendarDay__blocked_out_of_range):hover {
    background-image: url(/static/images/calendar/end-hover.svg) !important;
    color: #fff;
  }
}

.CalendarDay__selected_start {
  background-image: url(/static/images/calendar/start-selected.svg);
}
.CalendarDay__selected_end {
  background-image: url(/static/images/calendar/end-selected.svg);
}
.CalendarDay__hovered_span {
  // NOTE: workaround for https://trello.com/c/m7PUlwBh/112-react-date-select
  &:not(.CalendarDay__before_hovered_end) {
    &,
    &:hover {
      background-image: url(/static/images/calendar/middle-hover.svg) !important;
      color: #fff;
    }
  }
}

.CalendarDay__blocked_calendar.CalendarDay__selected_span,
.CalendarDay__selected_span {
  &,
  &:hover {
    background-image: url(/static/images/calendar/middle-selected.svg);
    color: #fff;
  }
}
