.contact-data {
  background: $secondary-color;
  color: #fff;
  line-height: 1.8;
  font-weight: 400;
  margin-bottom: 10px;
  list-style: none;

  .contact-icon {
    width: 30px;
    height: 30px;
    float: left;
    background: white;
    border-radius: 50%;
    @include flex-center;
    margin-right: 10px;

    .mail-icon {
      width: 15px;

      .cls-1 {
        stroke-width: 5px;
        stroke: $secondary-color;
      }
    }

    .skype-icon {
      width: 12px;

      .cls-1 {
        stroke-width: 3px;
        stroke: $secondary-color;
      }
    }

    .skype-new-icon {
      width: 15px;

      .cls-1 {
        fill: $secondary-color;
      }
    }

    .phone-icon {
      width: 13px;

      path {
        stroke-width: 3px;
        stroke: $secondary-color;
      }
    }

    .smartphone-icon {
      width: 8px;

      .cls-1 {
        stroke-width: 3px;
        stroke: $secondary-color;
      }
    }
  }

  .icons-ul {
    li {
      padding-bottom: 22px;
    }
  }

  ul {
    padding-top: 20px;
    list-style-type: none;
    padding-left: 15px;

    &:last-child {
      padding-top: 0;
    }

    &:first-child {
      padding-top: 20px;
    }
  }

  .last-ul {
    ul {
      &:first-child {
        padding-top: 0;
      }
    }
  }
}
