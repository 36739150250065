.container-fluid {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

body {
  color: $primary-color;
  font-family: $font-2;
  // font-family: 'Playfair Display', serif;
}

.thank-you-message {
  background: #388e3c;

  color: #fff;
  margin: 0 0 30px;
  position: relative;
  padding: 30px !important;
  display: inline-block;
  width: 100%;

  animation-name: thank-you-message-in-animation;
  animation-duration: 1s;
  opacity: 1;
  filter: alpha(opacity=1);

  &:before {
    border: 1px solid rgba(255, 255, 255, 0.25);
    content: "";
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
  }

  p {
    padding: 0 0 10px;
    color: white;
    margin: 0;

    &:last-child {
      padding: 0;
    }
  }
}

@keyframes thank-you-message-in-animation {
  0% {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    filter: alpha(opacity=100);
  }
}

.modal-backdrop {
  z-index: 9999;
}

.modal {
  z-index: 99999;
}

.backToTop {
  height: 40px;
  width: 40px;
  background: #2b3b42;
  position: fixed;
  bottom: 20px;
  right: 20px;
  @include flex-center;
  cursor: pointer;

  .caret-down {
    height: 20px;
    width: 20px;
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
    transform: rotate(180deg);

    .cls-1 {
      stroke: white;
    }
  }
}

.transition-container--horizontal {
  height: 320px !important;
}

#loader {
  position: relative;
  margin: auto;
  @include flex-center;
  height: 200px;
  width: 100%;

  ul {
    margin: 0;
    list-style: none;
    width: 90px;
    position: relative;
    padding: 0;
    height: 10px;

    li {
      position: absolute;
      width: 2px;
      height: 0;
      background-color: #ceb896;
      bottom: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  body.modal-open {
    position: fixed;
    width: 100%;
  }
}

@keyframes sequence1 {
  0% {
    height: 10px;
  }
  50% {
    height: 50px;
  }
  100% {
    height: 10px;
  }
}

@keyframes sequence2 {
  0% {
    height: 20px;
  }
  50% {
    height: 65px;
  }
  100% {
    height: 20px;
  }
}

#loader li:nth-child(1) {
  left: 0;
  animation: sequence1 1s ease infinite 0;
}
#loader li:nth-child(2) {
  left: 15px;
  animation: sequence2 1s ease infinite 0.1s;
}
#loader li:nth-child(3) {
  left: 30px;
  animation: sequence1 1s ease-in-out infinite 0.2s;
}
#loader li:nth-child(4) {
  left: 45px;
  animation: sequence2 1s ease-in infinite 0.3s;
}

#loader li:nth-child(5) {
  left: 60px;
  animation: sequence1 1s ease-in-out infinite 0.4s;
}
#loader li:nth-child(6) {
  left: 75px;
  animation: sequence2 1s ease infinite 0.5s;
}
